
//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed var(--#{$prefix}gray-300);
  background: $card-bg;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
}

// dark
[data-layout-mode="dark"]{
  .dropzone{
    background: rgba($card-bg, .02);
  }
}